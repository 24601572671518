import React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Navigate() {
  let navigate = useNavigate();

  React.useEffect(() => {
    // Assuming '/path/to/your/pdf/file.pdf' is an absolute URL
    window.location.href = 'https://drive.google.com/file/d/1DQpO7Sql3NbEZhgbbkL4ZkyNNlhZ7hH4/view';
  }, [navigate]);

  return (
    <div></div>
  )
}


function App() {


  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/*" element={<Navigate />} />
      </Routes>
    </Router>
  </div>
  )
}

export default App;
